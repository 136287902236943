import React from 'react'
import Layout from '../../components/Layout'

export default () => (
  <Layout>
    <section className="section">
      <div className="container">
      <div className="content center flex flex-col"style={{minHeight: '65vh'}}>
          <h1>Thank you!</h1>
          <p>I've received your Contact Request.</p>
          <p>You should hear from me in about 24-48 hrs.</p>
        </div>
      </div>
    </section>
  </Layout>
)
